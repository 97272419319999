module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: "Pomme Journal", // Navigation and Site Title
  siteTitleAlt: "Pomme Journal", // Alternative Site title for SEO
  siteTitleManifest: "pomme-journal",
  siteUrl: "https://pommejournal.com", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteHeadline: "Pomme Journal. A lit mag.", // Headline for schema.org JSONLD
  siteBanner: "/social/logo-icon.png", // Your image for og:image tag. You can find it in the /static folder
  favicon: "src/favicon.png", // Your image for favicons. You can find it in the /src folder
  siteDescription:
    "Pomme Journal is a brand new literary publication striving to be a fresh platform showcasing up-and-coming voices. Get to know us a little, then submit your work! Our first issue will be published fall 2019.", // Your site description
  author: "@grvangotten", // Author for schemaORGJSONLD
  siteLogo: "/social/logo-icon.png", // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  // userTwitter: '@minimal', // Twitter Username - Optional
  // ogSiteName: 'minimal', // Facebook Site Name - Optional
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "UA-135499941-1",

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: "#1D5A49",
  backgroundColor: "#F05530",
  keywords: "Pomme Journal, Lit Mag, Literary Magazine, Literature, Poetry, New Literary Magazine"
};
