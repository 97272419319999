import { lighten } from "polished";

// themeColor: "#8B0000",
// backgroundColor: "#2b2e3c"

const colors = {
  primary: "#1D5A49", // Color for buttons or links
  secondary: "#F05530",
  primaryLight: lighten(0.05, "#1D5A49"),
  bg: "#2b2e3c", // Background color
  grey: {
    dark: "rgba(255, 255, 255, 0.9)",
    default: "rgba(255, 255, 255, 0.7)",
    light: "rgba(255, 255, 255, 0.5)",
    ultraLight: "rgba(255, 255, 255, 0.25)"
  },
  dark: {
    dark: "rgba(0, 0, 0, 0.9)",
    default: "rgba(0, 0, 0, 0.7)",
    light: "rgba(0, 0, 0, 0.5)",
    ultraLight: "rgba(0, 0, 0, 0.25)"
  },
  white: "white",
  appGrey: "#58595B",
  appText: "#231F20",
  appGreyLight: lighten(0.37, "#58595B"),
  appOffWhite: "#ccc",
  tertiary: "#D8B42D",
  lightSecondary: "#BA5E52",
  lightTertiary: "#CDCA77"
};

const transitions = {
  normal: "0.5s"
};

const fontSize = {
  small: "0.9rem",
  medium: "1rem"
};

const fontFamily = {
  // serif: `'Bitter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', serif`,
  sansSerif: `'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif`
};

const breakpoints = {
  tablet: "1200px",
  phone: "600px"
};

const theme = {
  colors,
  transitions,
  fontSize,
  breakpoints,
  fontFamily,
  maxWidth: "800px",
  baseFontSize: "18px",
  primary: "#1D5A49", // Color for buttons or links
  secondary: "#F05530",
  appGrey: "#58595B",
  appText: "#231F20",
  appGreyLight: lighten(0.37, "#58595B"),
  appOffWhite: "#ccc",
  tertiary: "#D8B42D",
  lightSecondary: "#BA5E52",
  lightTertiary: "#CDCA77"
};

export default theme;


export const appColors = {
  appGrey: "#58595B",
  appText: "#231F20",
  appGreyLight: lighten(0.37, "#58595B"),
  appOffWhite: "#ccc",
  primary: "#1D5A49",
  secondary: "#F05530",
  tertiary: "#D8B42D",
  lightSecondary: "#BA5E52",
  lightTertiary: "#CDCA77"
};

// const theme = createMuiTheme({
//   breakpoints: {
//     keys: ["xs", "sm", "md", "lg", "xl"],
//     values: {
//       xs: 0,
//       sm: 750,
//       md: 960,
//       lg: 1280,
//       xl: 1920
//     }
//   },
//   palette: {
//     primary: {
//       superLight: "#7A7A7A",
//       light: "#627686",
//       main: "#374a59",
//       dark: "#0e2230",
//       visited: "grey",
//       contrastText: "#fff"
//     },
//     secondary: {
//       light: "#5F47B5",
//       main: "#E64534",
//       dark: "#100536",
//       contrastText: "#FFF"
//     },
//     tertiary: "#64F7FF",
//     accent: {
//       orange: "#FB7451",
//       yellow: "#F4B747",
//       burnt: "#E64534",
//       peach: "#F2A17D",
//       red: "#CC443C",
//       lightYellow: "#ffdca4"
//     }
//   },
//   typography: {
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       "-apple-system",
//       "BlinkMacSystemFont",
//       '"Segoe UI"',
//       "Roboto",
//       '"Helvetica Neue"',
//       "Arial",
//       "sans-serif",
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"'
//     ].join(",")
//   }
// });

// export default theme;
