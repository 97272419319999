import {Link} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import {PommeLogoTrimmed} from '../images/svg/pomme-logo'

const StyledLink = styled(Link)`
  margin: 0 0 0 1rem;
  color: ${({theme}) => theme.secondary};
  text-decoration: none;
  font-family: bodonixtregular;
  transition: all 200 ms;
  &:hover {
    color: ${({theme}) => theme.lightSecondary};
  }
`
const NavLink = props => (
  <StyledLink {...props} activeStyle={{color: '#231F20'}} />
)

const SiteNav = () => (
  <div
    style={{
      padding: `1rem 0 `,
      display: 'flex',
      alignItems: 'flex-end',
    }}
  >
    <NavLink to="/submit">SUBMIT</NavLink>
    <NavLink to="/store">STORE</NavLink>
    <NavLink to="/journals" partiallyActive={true}>
      JOURNALS
    </NavLink>
    <NavLink to="/about">ABOUT</NavLink>
  </div>
)

const StyledHeader = styled.header`
  /* background: ${({theme}) => theme.primary}; */
  padding: 0;
  background: transparent;
  margin-bottom: 1.45rem;
  /* border-bottom: 2px solid black; */
`

const HeaderLinks = styled.div`
  padding: 0.45rem 1rem;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: center;
`

const HeaderBrandColor = styled.div`
  background-color: ${({theme}) => theme.secondary};
`

const HeaderContent = styled.div`
  padding: 0.45rem 1rem;
  max-width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
`

const HeaderBrand = styled(Link)`
  width: 100%;
  background-color: ${({theme}) => theme.secondary};
`

const Header = ({siteTitle}) => (
  <StyledHeader>
    <HeaderBrandColor>
      <HeaderContent>
        <HeaderBrand to="/">
          <h1 style={{margin: 0, maxWidth: '1180px', padding: 0}}>
            <PommeLogoTrimmed width={'100%'} color={'white'} />
          </h1>
        </HeaderBrand>
      </HeaderContent>
    </HeaderBrandColor>
    <HeaderLinks>
      <SiteNav />
    </HeaderLinks>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export const ShortHeader = ({siteTitle}) => (
  <StyledHeader>
    <HeaderBrandColor>
      <HeaderContent>
        <HeaderBrand to="/">
          <h1 style={{margin: 0, maxWidth: '1180px', padding: 0}}>
            <PommeLogoTrimmed width={'100%'} color={'white'} />
          </h1>
        </HeaderBrand>
      </HeaderContent>
    </HeaderBrandColor>
    <HeaderLinks>
      <SiteNav />
    </HeaderLinks>
  </StyledHeader>
)

ShortHeader.propTypes = {
  siteTitle: PropTypes.string,
}

ShortHeader.defaultProps = {
  siteTitle: ``,
}

export default Header
