import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const SlimHeader = styled.span`
  font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-weight: 400;
  color: ${({ theme }) => theme.primary};
`;
const BoldHeader = styled.span`
  font-family: Open Sans Condensed, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.primary};
`;

export const SectionHeader = styled.h2`
  font-family: bodonixtregular, georgia, serif;
  font-weight: bold;
  color: ${({ theme }) => theme.appText};
  text-transform: uppercase;
`;

export const CoolHeader = ({ children }) => {
  let txt = children.split(" ");
  let slimTxt = txt.splice(0, Math.floor(txt.length / 2));
  return (
    <h1>
      <SlimHeader>{slimTxt.join(" ")}</SlimHeader>
      <BoldHeader>{` ${txt.join(" ")}`}</BoldHeader>
    </h1>
  );
};

export default CoolHeader;
