import React from "react";
const PommeLogo = ({
  color = "#231F20",
  height = "768px",
  width = "1024px"
}) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    // width="1024px"
    // height="768px"
    width={width}
    viewBox="0 0 1024 768"
    enable-background="new 0 0 1024 768"
  >
    <g>
      <g>
        <path
          fill={color}
          d="M57.233,426.547h13.814v-192.26H57.233v-5.18h63.317c39.718,0,77.422,8.922,77.422,54.108
			c0,51.807-47.201,54.109-76.559,54.109h-7.195v89.223h15.255v5.182H57.233V426.547z M114.219,332.144h4.893
			c28.207,0,32.236-14.103,32.236-48.928c0-35.401-5.181-48.928-32.236-48.928h-4.893V332.144z"
        />
        <path
          fill={color}
          d="M308.214,225.077c44.036,0,72.528,49.504,72.528,105.34c0,55.836-28.492,105.34-72.528,105.34
			s-72.529-49.504-72.529-105.34C235.685,274.582,264.178,225.077,308.214,225.077z M332.391,263.069
			c0-21.874-7.771-32.811-24.177-32.811s-24.176,10.937-24.176,32.811v134.698c0,21.871,7.771,32.809,24.176,32.809
			s24.177-10.938,24.177-32.809V263.069z"
        />
        <path
          fill={color}
          d="M414.422,426.547c13.239,0,15.253-2.303,15.253-27.055V234.287h-13.814v-5.18h59.864l31.947,125.774h0.575
			l31.947-125.774h58.139v5.18H584.52v192.26h13.814v5.182h-70.802v-5.182h15.542V239.468h-0.575l-47.49,192.26h-9.499
			L437.16,237.742h-0.577v161.75c0,24.752,2.592,27.055,15.542,27.055v5.182h-37.702V426.547z"
        />
        <path
          fill={color}
          d="M625.974,426.547c13.238,0,15.253-2.303,15.253-27.055V234.287h-13.814v-5.18h59.864l31.947,125.774h0.575
			l31.948-125.774h58.138v5.18h-13.813v192.26h13.813v5.182h-70.802v-5.182h15.542V239.468h-0.575l-47.489,192.26h-9.499
			l-48.351-193.987h-0.577v161.75c0,24.752,2.592,27.055,15.542,27.055v5.182h-37.702V426.547z"
        />
        <path
          fill={color}
          d="M839.539,426.547h13.813v-192.26h-13.813v-5.18h123.472v53.246h-5.181
			c-2.304-21.298-11.225-48.065-48.642-48.065h-12.664v89.798c25.904,0,28.494-16.693,29.646-37.704h5.18v83.754h-5.18
			c-0.288-22.162-3.742-40.87-29.646-40.87v97.281h11.802c39.719,0,50.654-29.646,53.821-61.593h4.604l-2.303,66.774H839.539
			V426.547z"
        />
      </g>
      <g>
        <g>
          <path
            fill={color}
            d="M72.228,524.954v2.686c0,4.527,0.922,8.211,5.833,8.29c5.832,0,5.832-5.374,5.832-10.898v-38.369h8.749
				v39.673c0,12.05-5.987,16.27-14.734,16.194c-11.204-0.079-13.814-6.832-13.968-14.045v-3.53H72.228z"
          />
          <path
            fill={color}
            d="M204.398,514.058c0-19.57,5.526-28.471,18.111-28.471s18.111,8.9,18.111,28.471
				c0,19.569-5.526,28.472-18.111,28.472S204.398,533.627,204.398,514.058z M231.871,513.827c0-18.418-4.143-21.641-9.362-21.641
				s-9.362,3.223-9.362,21.641c0,18.879,4.143,22.103,9.362,22.103S231.871,532.706,231.871,513.827z"
          />
          <path
            fill={color}
            d="M360.819,486.662v39.291c0,6.907,2.764,9.977,8.058,9.977c5.219,0,7.982-3.069,7.982-9.977v-39.291h8.747
				v38.6c0,12.661-6.292,17.268-16.729,17.268c-10.513,0-16.805-4.606-16.805-17.268v-38.6H360.819z"
          />
          <path
            fill={color}
            d="M498.362,486.662H517.7c7.675,0,13.891,3.836,13.891,14.119c0,7.06-3.146,12.508-9.131,13.431v0.153
				c5.447,0.536,8.286,3.53,8.593,11.202c0.077,3.53,0.154,7.829,0.308,11.129c0.153,2.685,1.458,3.989,2.608,4.758h-9.897
				c-0.846-0.997-1.229-2.688-1.46-4.835c-0.306-3.3-0.229-6.369-0.382-10.358c-0.154-5.987-1.996-8.598-7.981-8.598h-7.137v23.791
				h-8.749V486.662z M514.095,511.063c5.601,0,8.747-3.377,8.747-8.901c0-5.831-2.531-8.9-8.288-8.9h-7.443v17.802H514.095z"
          />
          <path
            fill={color}
            d="M643.961,486.662h10.667l15.885,40.824h0.154v-40.824h8.288v54.792h-10.668l-15.885-41.899h-0.153v41.899
				h-8.288V486.662z"
          />
          <path
            fill={color}
            d="M802.763,486.662h11.203l14.427,54.792h-9.131l-3.377-13.431h-16.038l-3.53,13.431h-9.133
				L802.763,486.662z M814.427,521.04l-6.292-27.012h-0.153l-6.523,27.012H814.427z"
          />
          <path
            fill={color}
            d="M935.625,486.662h8.747v47.424h21.182v7.368h-29.929V486.662z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const PommeLogoTrimmed = ({
  color = "#231F20",
  height = "318px",
  width = "910px"
}) => (
  <svg
    // width="910"
    // height="318"
    width={width}
    viewBox="0 0 910 318"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.233002 201.547H14.047V9.28694H0.233002V4.10693H63.55C103.268 4.10693 140.972 13.0289 140.972 58.2149C140.972 110.022 93.771 112.324 64.413 112.324H57.218V201.547H72.473V206.729H0.233002V201.547ZM57.219 107.144H62.112C90.319 107.144 94.348 93.0409 94.348 58.2159C94.348 22.8149 89.167 9.28793 62.112 9.28793H57.219V107.144Z"
      fill={color}
    />
    <path
      d="M251.214 0.0769043C295.25 0.0769043 323.742 49.5809 323.742 105.417C323.742 161.253 295.25 210.757 251.214 210.757C207.178 210.757 178.685 161.253 178.685 105.417C178.685 49.5819 207.178 0.0769043 251.214 0.0769043ZM275.391 38.0689C275.391 16.1949 267.62 5.2579 251.214 5.2579C234.808 5.2579 227.038 16.1949 227.038 38.0689V172.767C227.038 194.638 234.809 205.576 251.214 205.576C267.619 205.576 275.391 194.638 275.391 172.767V38.0689Z"
      fill={color}
    />
    <path
      d="M357.422 201.547C370.661 201.547 372.675 199.244 372.675 174.492V9.28694H358.861V4.10693H418.725L450.672 129.881H451.247L483.194 4.10693H541.333V9.28694H527.52V201.547H541.334V206.729H470.532V201.547H486.074V14.4679H485.499L438.009 206.728H428.51L380.16 12.7419H379.583V174.492C379.583 199.244 382.175 201.547 395.125 201.547V206.729H357.423V201.547H357.422Z"
      fill={color}
    />
    <path
      d="M568.974 201.547C582.212 201.547 584.227 199.244 584.227 174.492V9.28694H570.413V4.10693H630.277L662.224 129.881H662.799L694.747 4.10693H752.885V9.28694H739.072V201.547H752.885V206.729H682.083V201.547H697.625V14.4679H697.05L649.561 206.728H640.062L591.711 12.7409H591.134V174.491C591.134 199.243 593.726 201.546 606.676 201.546V206.728H568.974V201.547V201.547Z"
      fill={color}
    />
    <path
      d="M782.539 201.547H796.352V9.28694H782.539V4.10693H906.011V57.3529H900.83C898.526 36.0549 889.605 9.28793 852.188 9.28793H839.524V99.0859C865.428 99.0859 868.018 82.3929 869.17 61.3819H874.35V145.136H869.17C868.882 122.974 865.428 104.266 839.524 104.266V201.547H851.326C891.045 201.547 901.98 171.901 905.147 139.954H909.751L907.448 206.728H782.539V201.547Z"
      fill={color}
    />
    <path
      d="M15.228 299.954V302.64C15.228 307.167 16.15 310.851 21.061 310.93C26.893 310.93 26.893 305.556 26.893 300.032V261.663H35.642V301.336C35.642 313.386 29.655 317.606 20.908 317.53C9.704 317.451 7.094 310.698 6.94 303.485V299.955H15.228V299.954Z"
      fill={color}
    />
    <path
      d="M147.398 289.058C147.398 269.488 152.924 260.587 165.509 260.587C178.094 260.587 183.62 269.487 183.62 289.058C183.62 308.627 178.094 317.53 165.509 317.53C152.924 317.53 147.398 308.627 147.398 289.058ZM174.871 288.827C174.871 270.409 170.728 267.186 165.509 267.186C160.29 267.186 156.147 270.409 156.147 288.827C156.147 307.706 160.29 310.93 165.509 310.93C170.728 310.93 174.871 307.706 174.871 288.827Z"
      fill={color}
    />
    <path
      d="M303.819 261.662V300.953C303.819 307.86 306.583 310.93 311.877 310.93C317.096 310.93 319.859 307.861 319.859 300.953V261.662H328.606V300.262C328.606 312.923 322.314 317.53 311.877 317.53C301.364 317.53 295.072 312.924 295.072 300.262V261.662H303.819V261.662Z"
      fill={color}
    />
    <path
      d="M441.362 261.662H460.7C468.375 261.662 474.591 265.498 474.591 275.781C474.591 282.841 471.445 288.289 465.46 289.212V289.365C470.907 289.901 473.746 292.895 474.053 300.567C474.13 304.097 474.207 308.396 474.361 311.696C474.514 314.381 475.819 315.685 476.969 316.454H467.072C466.226 315.457 465.843 313.766 465.612 311.619C465.306 308.319 465.383 305.25 465.23 301.261C465.076 295.274 463.234 292.663 457.249 292.663H450.112V316.454H441.363V261.662H441.362ZM457.095 286.063C462.696 286.063 465.842 282.686 465.842 277.162C465.842 271.331 463.311 268.262 457.554 268.262H450.111V286.064H457.095V286.063Z"
      fill={color}
    />
    <path
      d="M586.961 261.662H597.628L613.513 302.486H613.667V261.662H621.955V316.454H611.287L595.402 274.555H595.249V316.454H586.961V261.662Z"
      fill={color}
    />
    <path
      d="M745.763 261.662H756.966L771.393 316.454H762.262L758.885 303.023H742.847L739.317 316.454H730.184L745.763 261.662ZM757.427 296.04L751.135 269.028H750.982L744.459 296.04H757.427Z"
      fill={color}
    />
    <path
      d="M878.625 261.662H887.372V309.086H908.554V316.454H878.625V261.662Z"
      fill={color}
    />
  </svg>
);

export default PommeLogo;
