import React from "react";
import Link from "gatsby-link";
import styled from "styled-components";
import Theme from "../themes/default";
import { transparentize } from "polished";
import { PommeLogoTrimmed } from "../images/svg/pomme-logo";
import { TinyLetterForm, CallForEntriesLink } from "./Subscribe";
// import { FateIconImage } from "../components/image";

const StyledFooter = styled.section`
  color: white;
  margin: 0 auto;
  width: 100%;
  /* height: 100vh; */
  padding-bottom: 1rem;
  padding-top: 1rem;
  /* text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5); */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 0.75em;
  flex-shrink: 0;

  background-color: ${({ theme }) => theme.appText};

  p {
    font-size: inherit;
  }
`;
const FooterLinks = styled.div`
  margin: 1em auto;
  padding: 0 3rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ExternalLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: bold;
`;

const FooterLink = styled(Link)`
  /* text-decoration: none; */
  margin: 0.5em 1em;
  /* font-size: 20px; */
  color: white;
  &:visited {
    color: white;
  }
  &:focus {
    color: ${({ theme }) => theme.lightTertiary};
  }
  &:hover {
    color: ${({ theme }) => theme.lightTertiary};
  }
`;

const LogoSerif = styled.div`
  /* width: 400px; */
  font-size: 5rem;
  text-transform: uppercase;
  line-height: 3.45rem;
  margin: -1.05rem 0 0;
`;

const LogoSans = styled.div`
  /* width: 400px; */
  font-size: 40px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1rem;
  font-family: sans-serif;
  line-height: 40px;
`;
const TextLogo = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <LogoSerif>pomme</LogoSerif>
      <LogoSans>Journal</LogoSans>
      <p>Fresh New Fiction and Poetry</p>
    </div>
  );
};

class Footer extends React.Component {
  render() {
    return (
      <StyledFooter>
        <PommeLogoTrimmed color={"white"} width={"144px"} />

        <FooterLinks>
          <FooterLink to="/terms"> terms/conditions</FooterLink>
          {"|"}
          <FooterLink to="/privacy_policy"> privacy policy</FooterLink>
          {/* <FooterLink to="/rules"> rules</FooterLink> ·
          <FooterLink to="/about"> about RocketSweep</FooterLink> */}
        </FooterLinks>
        <p>
          Pomme Journal © {new Date().getFullYear()}
          {` | `}
          {"Built by "}
          <ExternalLink href="https://twitter.com/grvangotten">
            @Garrett
          </ExternalLink>
          {" with "}
          <ExternalLink href="https://www.gatsbyjs.org">Gatsby</ExternalLink>
        </p>
        <TinyLetterForm>
      Subscribe to Pomme Newsletter for the latest submission calls and
      deadlines
    </TinyLetterForm>
      </StyledFooter>
    );
  }
}

export default Footer;
